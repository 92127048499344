<template>
  <div class="onboard">
    <section>
      <div class="centered-box">
        <h1 class="title pt-6 txt-white txt-72">¡Bienvenido {{$route.query.first_name}} {{$route.query.last_name}}!</h1>
        <h3 class="txt-white is-size-4">
          Se envió un correo electrónico de confirmación a su cuenta en {{$route.query.email}}. Debe seguir las instrucciones del correo electrónico antes de poder activar su cuenta.
        </h3>
        <button @click="goTo('/')" class="button is-primary is-medium mt-6">
          Volver a la página de inicio
        </button><br>
        <button @click="sendConfirmationEmail()" class="button is-primary is-medium mt-2 send-conf">
          Envialo de nuevo
        </button>
      </div>
    </section>
  </div>
</template>

<script>

export default {
  name: 'WelcomeClinic',
  data () {
    return {}
  },
  methods: {
    goTo (path) {
      this.$router.push({ path: path })
    },
    sendConfirmationEmail () {
      const self = this
      this.$store.dispatch('user/sendConfirmationEmail', {
        params: {
          email: this.$route.query.email
        },
        cb: () => {
          self.$store.commit({
            type: 'application/ADD_FEEDBACK',
            feedback: {
              type: 'success',
              message: 'Se envió un correo electrónico de confirmación a su cuenta en ' + self.$route.query.email + '.'
            }
          })
        },
        cbError: (error) => {
          self.errorMessage = '¡Error!'
          self.$store.commit({
            type: 'application/ADD_FEEDBACK',
            feedback: {
              type: 'error',
              message: error
            }
          })
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
.onboard{
  position: fixed;
  height: calc(100% - 70px);
  width: 100%;
  top: 70px;
  left: 0px;
  section{
    position: relative;
    height: 100%;
    width: 100%;
    padding: 0px;
    background-position: bottom center;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url("../../assets/images/2-service-pages-hero.jpg");
    overflow: hidden;
    .centered-box{
      position: absolute;
      top: 40%;
      left: 50%;
      transform: translate(-50%, -50%);
      text-align: center;
      h3, h1{
        text-shadow: 1px 1px 2px rgba(0,0,0,0.6);
      }
      .send-conf{
        background-color: transparent;
        text-shadow: 1px 1px 2px rgba(0,0,0,0.6);
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .onboard{
    top: 49px;
    height: calc(100% - 49px);
    section{
      .centered-box{
        width: 100%;
        max-width: 600px;
        padding: 0 14px;
        .button{
          width: 100%;
        }
      }
    }
  }
}
</style>
